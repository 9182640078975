import app from './app';

app.initEchoSrv();
app.init();

window.onload = () => {
  if (window !== top) {
    document.querySelector('body').classList.add('no-header');
  }
};

setInterval(() => {
  parent.postMessage({ type: 'url', value: location.href }, '*');
}, 200);

(window as any).iFrameResizer = {
  heightCalculationMethod: () => {
    const height = document.getElementsByClassName('react-grid-layout')[0].scrollHeight + 20;
    return height;
  },
};

import 'iframe-resizer';
